import React from 'react';
import './directorio.css';

// Directly import the images
import image10 from '../../static/directorio/10.png';
import image11 from '../../static/directorio/11.png';
import image12 from '../../static/directorio/12.png';

function Directorio() {
  const images = [image10, image11, image12];

  return (
    <>
      <h1>Nuestro Directorio</h1>
      <div id="nos_dic">
        <ul>
          {images.map((image, index) => (
            <li key={index}>
              <img src={image} alt={`Director Member ${index + 1}`} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Directorio;
